// Theme Presets

const lightTheme = {
  name: "light",
  body: "#F0F2F5", // Light grey for background to provide contrast with dark blue
  text: "#1A1A1A", // Slightly off-black for text
  dark: "#000000", // Kept for darkest elements like text or icons
  secondaryText: "#7F8DAA", // Cooler secondary text color
  accentColor: "#286DA8", // Dark blue for accent elements
  accentBright: "#4B9CD3", // Brighter blue for more pronounced accent elements
  projectCard: "#D9E2EC", // Lighter blue shade for project cards to complement the dark blue
  skinColor: "#F7B799", // Unchanged as it is a skin color
  skinColor2: "#FCB696", // Unchanged as it is a skin color
  imageDark: "#dce4f2", // Unchanged, assuming it's for shadows or dark areas in images
  imageClothes: "#dce4f2", // A slightly different blue to add more variety
  avatarMisc: "#e9ecf2", // Light grey-blue for misc avatar items
  avatarShoes: "#ccd2e3", // Greyish blue for shoes to add depth
};

const darkTheme = {
  name: "dark",
  body: "#0A192F", // Dark blue as the primary background color
  text: "#E6F1FF", // Soft white for text for readability against dark blue
  dark: "#000000", // Kept for elements that require full black
  secondaryText: "#A8B2D1", // Lighter blue for secondary text
  accentColor: "#58A6FF", // Bright blue for accents to pop against the dark background
  accentBright: "#4D9DE0", // A different shade of bright blue for variety
  projectCard: "#112240", // Even darker blue for card backgrounds
  skinColor: "#B28B67", // A darker skin color for better contrast against dark theme
  skinColor2: "#C99A76", // Another skin color option with good contrast
  imageDark: "#112240", // Dark blue for dark areas of images to blend with the theme
  imageClothes: "#233554", // A different dark blue for clothing to create depth
  avatarMisc: "#14253D", // Slightly lighter dark blue for miscellaneous items
  avatarShoes: "#2B3A67", // A blue that stands out slightly for shoes
};

export const themes = { light: lightTheme, dark: darkTheme };
